<template>
    <div class="page">
      <div class="page-main">
        <div class="page-tools">
          <el-form :inline="true" :model="tabelSearch">
            <el-form-item>
              <el-button
                type="primary"
                @click="goback()"
                >{{ $t('device.back') }}
              </el-button>
              <el-button
                type="primary"
                icon="el-icon-delete iconfont icon-delete"
                @click="unbindItem()"
                :disabled="!this.currentUserId.length"
                @changeCurrentPage="getTabelData"
                >{{ $t('device.unbind') }}
              </el-button>
              <dev class = "account-info">
                {{ "     " + $t('device.currentAccount') +this.currentUserId + " - " + this.currentServer}}
              </dev>
            </el-form-item>
            <el-form-item class="page-tools-right keywords" v-has="'list'">
              <el-input
                v-model="tabelSearch.keywords"
                style="width: 260px"
                :placeholder="$t('sipAccount.keywordsPla')"
                prefix-icon="el-icon-search"
                clearable
                @change="getTabelData"
              >
              </el-input>
            </el-form-item>
            <el-form-item class="page-tools-right">
              <el-select
                v-model="tabelSearch.status"
                :placeholder="$t('sipAccount.sipStatusPla')"
                @change="getTabelData"
              >
                <el-option
                  v-for="status in statusOptions"
                  :key="status.value"
                  :label="status.label"
                  :value="status.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <table-pagination
          class="page-table"
          memory="sipAccount"
          ref="refTable"
          :height="pageTabelHeight"
          :tableData="filterTableData"
          :columnData="columnData"
          :selectionShow=false
          @selection-change="selectionChange"
          optionShow
          columnOptionShow
          :total="tabelTotal"
          :currentPage.sync="tabelSearch.currentPage"
          :pageSize.sync="tabelSearch.pageSize"
          @changeCurrentPage="getTabelData"
          @sort-change="tabelSortChange"
        >
          <!-- sipServer -->
          <template slot="sipServer" slot-scope="scope">
            {{scope.row.sipServer}}{{scope.row.port == 5060 ? '' : `:${scope.row.port}`}}{{`(${scope.row.serverName})`}}
          </template>
          <template slot="sipServerHeader">
            <div v-if="!serverOptions.length">{{$t('sipAccount.sipServer')}}</div>
            <el-popover v-else trigger="click">
              <div class="server-title" slot="reference">{{$t('sipAccount.sipServer')}}<i class="el-icon-arrow-down el-icon--right" ></i></div>
            </el-popover>
          </template>
          <!-- status -->
          <template slot="status" slot-scope="scope">
            <el-popover width="850" trigger="click" v-if="scope.row.status">
              <div class="total">{{$t('sipAccount.numOfDevices')}} {{tableData2.length}}</div>
              <table-pagination
                :tableData="tableData2"
                :columnData="columnData2"
                optionShow
                paginationHide
              >
                <template slot="status" slot-scope="scope">
                  <div :class="statusColor(scope.row.status)">
                    {{ deviceStatus[scope.row.status] }}
                  </div>
                </template>
                <template slot="accountStatus" slot-scope="scope">
                  <div :class="statusColor(scope.row.accountStatus)">
                    {{ deviceAccountStatus[scope.row.accountStatus] }}
                  </div>
                </template>
              </table-pagination>
              <el-button
                type="text"
                :class="['btn-p0', statusColor(scope.row.status)]"
                slot="reference"
                @click="getAccountData(scope.row)"
              >
                {{ accountStatus[scope.row.status] }}
                <i class="el-icon-arrow-down"></i>
              </el-button>
            </el-popover>
            <span :class="[statusColor(scope.row.status)]" v-else>{{
              accountStatus[scope.row.status]
            }}</span>
          </template>
          <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('device.assign')"
            placement="top"
            :open-delay="1000"
          >
            <el-button
              type="text"
              class="btn-p0"
              @click="bindItem(scope)"
              icon="el-icon-device iconfont icon-device"
              :disabled="scope.row.origin==2"
            ></el-button>
          </el-tooltip>
          <!-- <el-tooltip
            effect="dark"
            :content="$t('delete')"
            placement="top"
            :open-delay="1000"
            v-has="'delete'"
          >
          </el-tooltip> -->
        </template>
        </table-pagination>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapGetters } from 'vuex'
  import {
    accountSearch,
    unbindDevice
  } from '@/api/sipAccount'
  import { TablePagination, DialogImportItem } from '@/components'
  import mixinTable from '@/components/mixin/table'
  import { deviceAccountAssignOne, deviceAccountList, deviceQuery } from '@/api/device'
  
  export default {
    name: 'SipAccount',
    mixins: [mixinTable],
    components: {
      TablePagination,
      DialogImportItem,
    },
    data() {
      return {
        tabelSearch: {
          currentPage: 1,
          pageSize: 10,
          sortField: 'updateTime',
          sortOrder: 'desc',
          keywords: null,
          status: null,
          site: [],
        },
        statusOptions: [
          {label: this.$t('dist.deviceStatus.all'), value: null},
          {label: this.$t('dist.accountStatus.unassigned'), value: 0},
          {label: this.$t('dist.accountStatus.registered'), value: 1},
          {label: this.$t('dist.accountStatus.unregistered'), value: 2},
        ],
        allOptions: [],        // 全部选项
        serverList: [],        // 选择的服务
        serverOptions: [],     // 可选服务
        tabelTotal: 0,
        columnData: [
          {
            label: this.$t('sipAccount.accountName'),
            prop: 'accountName',
            minWidth: 160,
          },
          {
            label: this.$t('sipAccount.displayName'),
            prop: 'displayName',
            minWidth: 150,
          },
          {
            label: this.$t('sipAccount.userName'),
            prop: 'sipUserId',
            minWidth: 130,
            sortable: 'custom',
          },
          {
            label: this.$t('sipAccount.sipServer'),
            prop: 'sipServer',
            minWidth: 180,
            // filterEnable: true,
            // filterAll: {text: 'All', value: 'all'},
            slot: true,
            headerSlot: true,
          },
          {
            label: this.$t('status'),
            prop: 'status',
            minWidth: 150,
            slot: true,
          },
          
        ],
        tableData: [],
        columnData2: [
          {
            label: this.$t('device.macAddress'),
            prop: 'macAddress',
            minWidth: 200,
            filter: 'toUpperCase',
          },
          {
            label: this.$t('device.deviceName'),
            prop: 'deviceName',
            minWidth: 140,
          },
          {
            label: this.$t('sipAccount.model'),
            prop: 'deviceModel',
            minWidth: 100,
          },
          {
            label: this.$t('sipAccount.deviceStatus'),
            prop: 'status',
            minWidth: 140,
            filter: (val) => this.deviceStatus[val],
            slot: true,
          },
          {
            label: this.$t('device.accountStatus'),
            prop: 'accountStatus',
            minWidth: 140,
            filter: (val) => this.accountStatus[val],
            slot: true,
          },
        ],
        tableData2: [],
        // 导入
        importVisible: false,
        currentUserId: '',
        currentServer: '',
        currentAccountId: ''
      }
    },
    computed: {
      ...mapState('memory', ['language']),
      ...mapGetters('dist', ['accountStatus', 'deviceStatus', 'deviceAccountStatus']),
      ...mapState('session', ['appHeight']),
      ...mapGetters('session', ['pageTabelHeight']),
      importMsgTable() {
        return {
          title: this.$t('sipAccount.importAccount'),
          columnData: [
            {
              label: this.$t('sipAccount.userName'),
              prop: 'sipUserId',
              minWidth: 100,
            },
            {
              label: this.$t('sipAccount.sipServer'),
              prop: 'sipServer',
              minWidth: 100,
            },
            {
              label: this.$t('failedReason'),
              prop: this.language === 'en' ? 'failReasonEn' : 'failReason',
              minWidth: 200,
            },
          ]
        }
      },
      statusColor() {
        return (status) => {
          const statusClass = {
            0: 'unassigned-color',
            1: 'registered-color',
            2: 'unregistered-color'
          }
          return statusClass[status]
        }
      },
      // 过滤表格数据
      filterTableData() {
        let list = []
        for (let row of this.tableData) {
          if (this.serverList.indexOf(row.sipServer) != -1) {
            list.push(row)
          }
        }
        return list
      },
      // 服务全选
      checkAllServer: {
        get() {
          return this.serverOptions.length == this.serverList.length
        },
        set() {}
      }
    },
    created() {
      if (this.$route.query.status) this.tabelSearch.status = +this.$route.query.status
      this.currentUserId = this.$route.query.sipUserId
      this.currentServer = this.$route.query.sipServer
      this.currentAccountId = this.$route.query.accountId
      this.tableInit()
    },
    methods: {
      // 全选服务
      changeAllServer(val) {
        console.log('全选', val)
        // this.serverList = null
        this.serverList = val ? this.allOptions : []
      },
      // 获取服务选项
      getServerOptions() {
        let arr = []
        if (Array.isArray(this.tableData)) {
          this.tableData.forEach((row) => {
            if (!arr.includes(row.sipServer)) {
              arr.push(row.sipServer)
            }
          })
        }
        this.allOptions = arr
        this.serverList = arr
        this.serverOptions = arr.map((name) => {
          return {
            text: name,
            value: name,
          }
        })
      },
      // 获取数据
      getTabelData() {
        accountSearch(this.tabelSearch)
          .then(({ data }) => {
            this.tableData = data.result.rows[0].list // 表格数据
            this.tabelTotal = data.result.rows[0].total // 表格数据条目
            this.$refs.refTable.clearFilter() // 表格过滤清空
            this.getServerOptions()
          })
          .catch(() => {})
      },
      // 获取账号数据
      getAccountData(row) {
        this.tableData2 = row.devices.map(item => {
          return {...item, accountStatus: item.pushStatus}
        })
      },
           
      // 解除绑定
      unbindItem() {
        this.$confirm(this.$t('sipAccount.unbindItem'), this.$t('tip'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning',
        })
          .then(() => {
            let data = {
              accountId: this.currentAccountId,
              deviceId: this.$route.query.deviceId
            }
            unbindDevice(data)
              .then(() => {
                this.currentUserId = ''
                this.currentServer = ''
                this.getTabelData()
                this.refresh()
              })
              .catch(() => {})
          })
          .catch(() => {})
      },
      bindItem(scope) {
        this.duplicateDeviceAccount(scope.row.id, (tipStr) => {
          //console.log('bindItem dup:', tipStr)
          this.$confirm(
             tipStr, 
            this.$t('tip'), {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning',
          })
          .then(() => {
            this.currentAccountId = scope.row.id
            this.currentUserId = scope.row.sipUserId
            this.currentServer = scope.row.sipServer
            let req = []
            console.log('bindItem', this.currentAccountId, this.currentUserId, this.currentServer)
            req.push({
              accountId: this.currentAccountId,
              deviceId: this.$route.query.deviceId,
              no: this.$route.query.accountNo,
            })
            if (!req.length) {
              req = [
                {
                  deviceId: this.$route.query.id,
                },
              ]
            }

            deviceAccountAssignOne(req)
              .then(({ data }) => {
                if (this.$route.query.pushStatus == 0) {
                  this.$message.success(data.message)
                  this.$router.go(-1)
                } else {
                  console.log("push in DeviceAccountAssign")
                  this.push()
                  this.$router.go(-1)
                }
                //this.refresh()
              })
             .catch(() => {})
          })
        } )
        },
        goback() {
          this.$router.go(-1)
          /*if (this.$route.query.model === null) {
            this.$router.go(-1)
          } else {
            this.$router.push(
              { path: '/device/editDevice' , 
                query: {
                  id: this.$route.query.deviceId,
                  model: this.$route.query.model,
                }
              }
            )
          }*/
        },
        duplicateDeviceAccount(accountId, callback) {
          deviceAccountList( {id: this.$route.query.deviceId })
            .then(({ data }) => {
              for (let i in data.result.rows) {
                //console.log('duplicateDeviceAccount:', i, data.result.rows[i].id)
                if (data.result.rows[i].id == accountId) {
                  
                  callback(this.$t('sipAccount.bindDuplicateItem'))
                  return
                }
              }
              //console.log('duplicateDeviceAccount: false')
              callback(this.$t('sipAccount.bindItem'))
          })
        .catch(() => {callback(this.$t('sipAccount.bindItem'))})
      
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .iconfont {
    display: flex;
    align-items: center;
  }
  .registered-color {
    color: #3ab477;
  }
  .unregistered-color {
    color: #f1c42c;
  }
  .unassigned-color {
    color: #999;
  }
  .total {
    text-align: center;
  }
  .check-all {
    display: flex;
    padding: 0 10px;
  }
  .server-title {
    &:hover, &:active {
      cursor: pointer;
    }
  }
  .server-list {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
  .el-icon-arrow-down {
    font-size: 16px;
  }
  .account-info {
    font-size: 18px;
  }
  </style>
  